import { datadogRum } from '@datadog/browser-rum';

export default {
    install(Vue) {
        // Configurar o Datadog RUM com seu aplicativo's clientToken e applicationId
        datadogRum.init({
            applicationId: 'd2fe24ca-0876-460a-9447-a38002001a98',
            clientToken: 'pubc1de8eb74bc9a6aab56e93a7c29c8d8a',
            // `site` refers to the Datadog site parameter of your organization
            // see https://docs.datadoghq.com/getting_started/site/
            site: 'datadoghq.com',
            service: 'consignado-webbackoffice-prd',
            env: 'prod',
            // Specify a version number to identify the deployed version of your application in Datadog
            // version: '1.0.0', 
            sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
        });

        // Expondo o objeto datadogRum globalmente no Vue
        Vue.prototype.$datadogRum = datadogRum;
    },
};